import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import queryString from "query-string";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Error499 = () => {

  const { search } = useLocation();

  const [homeLink, setHomeLink] = useState("/");

  useEffect(() => {
      let temp = queryString.parse(search);
  
      if (temp && temp.homeLink){
          setHomeLink(temp.homeLink);
      }    
  }, [search, setHomeLink]);
  
  return (
    <Card className="text-center">
      <CardBody className="p-5">
        <div className="display-1 text-200 fs-error">Oops!</div>
        <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
          You have tried too many times!
        </p>
        <hr />
        <p>
          We limit the number of times certain actions can be performed to prevent abuse. If you think this is a mistake,
          <a href="mailto:info@crowdcatcher.co" className="ml-1">
            contact us
          </a> or try again later.
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to={homeLink}>
          <FontAwesomeIcon icon="home" className="mr-2" />
          Take me home
        </Link>
      </CardBody>
    </Card>
  );
}

export default Error499;
