import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import AppContext from '../../context/Context';
import {urls} from '../../config';
import withRedirect from '../../hoc/withRedirect';
import Loader from '../common/Loader';
import { v4 as uuidv4 } from 'uuid';
import { createCookie } from '../../helpers/utils';

const LoginForm = ({ hasLabel, setRedirect, redirectAfterSuccess, setForgotPassword, emailAddr, setLoginSuccess }) => {
  
  // State
  const [email, setEmail] = useState(emailAddr ? emailAddr : '');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const {setUserData} = useContext(AppContext);
  const [isLoggingIn, setLoggingIn] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();   

    let responseCode = 200;  
    setUserData([]);  
    createCookie("cc_userdata", null, 0);

    fetch(urls.account + 'Users/authenticate', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({"username": email, "password": password})
    })
    .then(response=>{
        responseCode = response.status;
        return response.json();
    })            
    .then(data=>{

        if (responseCode !== 200){

          setLoggingIn(false);
          toast.error(responseCode + " Error logging in");
          return;
        }
        
        if (data){

            setLoggingIn(false);

            if (!data.Tokens || data.BasicAuthenticationOk === false){
              toast.error("Unable to login, try again!");
              return;
            }
            
            toast.success(data.FirstName + ", you are now logged in!");
            setUserData(data);
            if (remember){
              createCookie("cc_userdata", JSON.stringify(data), 86000000);  // just less than one day!
            }
            else{
              createCookie("cc_userdata", null, 0);
            }

            if (redirectAfterSuccess && redirectAfterSuccess === true){
              setRedirect(data);
            }

            if (setLoginSuccess){
              setLoginSuccess();
            }              
        }
    },
    (error)=>{
      setLoggingIn(false);
      toast.error("FAILED!");
    });
  };

  const rememberMe = e => {
    setRemember(!remember);
  }

  useEffect(() => {
    setIsDisabled(!email || !password || isLoggingIn);
  }, [email, password, isLoggingIn]);

  const forgotPassword = (e) => {
    if (setForgotPassword){
      e.preventDefault();
      setForgotPassword(true);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          id="txtUsername"
          autoFocus={true}
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          id='txtPassword'
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id={uuidv4()}
            name="chkRemember"
            label="Remember me"
            checked={remember}
            onChange={rememberMe}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" onClick={forgotPassword} to={'/auth/forgot-password'}>
            Forget Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        {isLoggingIn && <Loader size="lg" padding="2" type="border" />}
        {!isLoggingIn &&
          <Button id="btnLogin" color="primary" block className="mt-3" disabled={isDisabled}>
            Log in
          </Button> 
        }
      </FormGroup>
      
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  redirectAfterSuccess: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false,
  redirectAfterSuccess: true
};

export default withRedirect(LoginForm);
